import styled from "@emotion/styled";
import { BrandTitle, BrandText, ProductText } from "../../global/typography"
import { colors } from "../../global";
import { brands } from "../../global/text"
import { logoSinteplast, logo2001, logoSulan, logoCastor, logoTytan } from "../../assets"
import { BrandSection } from "./BrandSection"
import { breakpoints } from "../../global/breakpoints";
import { list as listSinteplast } from "../../assets/sinteplast";
import { list as listSulan } from "../../assets/sulan";
import { list as list2001 } from "../../assets/auto2001";
import { list as listTytan } from "../../assets/tytan";
import { list as listCastor } from "../../assets/castor";





const GroupContainer = styled.div<{ startColor: string, endColor: string }>`
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, ${({ startColor }) => startColor} 0, ${({ endColor }) => endColor} 100% );
`

const GroupTitleContainter = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(min-width: ${breakpoints.desktop}) {
    height: auto;
    margin: 32px 0;
  }
`
const BrandsTemplate = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0px;
`
const TitleWrapper = styled.div<{ borderColor: string }>`
@media(min-width: ${breakpoints.desktop}){
border: 4px ${(({ borderColor }) => borderColor)} solid;
padding: 32px 120px;
display: flex;
border-radius: 10px;
background-color: #ffffff88;
/* filter: drop-shadow(-4px 4px 4px); */
}
`
export const Brands = () => {
  const logoSize = {
    sinteplast: {
      mobile: {
        width: "202px",
        height: "92px"
      },
      desktop: {
        width: "202px",
        height: "92px"
      }
    },
    sulan: {
      mobile: {
        width: "162px",
        height: "56px"
      },
      desktop: {
        width: "162px",
        height: "56px"
      }
    },
    2001: {
      mobile: {
        width: "172px",
        height: "52px"
      },
      desktop: {
        width: "172px",
        height: "52px"
      }
    },
    tytan: {
      mobile: {
        width: "150px",
        height: "78px"
      },
      desktop: {
        width: "150px",
        height: "78px"
      }
    },
    castor: {
      mobile: {
        width: "192px",
        height: "48px"
      },
      desktop: {
        width: "192px",
        height: "48px"
      }
    },
  }

  return (
    <BrandsTemplate>
      <GroupContainer startColor={colors.backgroundColors.red[0]} endColor={colors.backgroundColors.red[1]}>
        <GroupTitleContainter>
          <TitleWrapper borderColor="#48291488">
            <BrandTitle>Grupo Sinteplast</BrandTitle>
          </TitleWrapper>
        </GroupTitleContainter>
      </GroupContainer>
      <BrandSection
        breakpoint={95}
        startColor={colors.backgroundColors.red[1]}
        endColor={colors.backgroundColors.red[2]}
        textList={brands.sinteplast}
        buttonColor={colors.buttonColors.green}
        Logo={logoSinteplast}
        LogoSize={logoSize.sinteplast}
        cardBackground="#F3E9E2"
        dotListClass="dot-list-red"
        id="sinteplast"
        borderColor="#48291488"
        imgList={listSinteplast}
        catalogLink="https://drive.google.com/file/d/1h7MbOAlScMKyUZxckdYUGxszBLV6IUrs/view?usp=sharing"
        brandCode="414811"
      />
      <BrandSection
        breakpoint={95}
        startColor={colors.backgroundColors.red[2]}
        endColor={colors.backgroundColors.red[3]}
        textList={brands.sulan}
        buttonColor={colors.buttonColors.green}
        Logo={logoSulan}
        LogoSize={logoSize.sulan}
        cardBackground="#F3E9E2"
        dotListClass="dot-list-red"
        id="sulan"
        borderColor="#48291488"
        imgList={listSulan}
        catalogLink="https://drive.google.com/file/d/1h7MbOAlScMKyUZxckdYUGxszBLV6IUrs/view?usp=sharing"
        brandCode="414811"
      />
      <BrandSection
        breakpoint={95}
        startColor={colors.backgroundColors.red[3]}
        endColor={colors.backgroundColors.green[0]}
        textList={brands[2001]}
        buttonColor={colors.buttonColors.green}
        Logo={logo2001}
        LogoSize={logoSize[2001]}
        cardBackground="#F3E9E2"
        dotListClass="dot-list-red"
        id="2001"
        borderColor="#48291488"
        imgList={list2001}
        catalogLink="https://drive.google.com/file/d/1fwG6DvO563wmnvxpDwh5K9D0f7Z8yLqw/view?usp=sharing"
        brandCode="414811"
      />
      <GroupContainer startColor={colors.backgroundColors.green[0]} endColor={colors.backgroundColors.green[1]}>
        <GroupTitleContainter>
          <TitleWrapper borderColor="#1a3f1788">
            <BrandTitle>Outras Marcas</BrandTitle>
          </TitleWrapper>

        </GroupTitleContainter>
      </GroupContainer>
      <BrandSection
        breakpoint={95}
        startColor={colors.backgroundColors.green[1]}
        endColor={colors.backgroundColors.green[2]}
        textList={brands.tytan}
        buttonColor={colors.buttonColors.purple}
        Logo={logoTytan}
        LogoSize={logoSize.tytan}
        cardBackground="#EBF6E9"
        dotListClass="dot-list-green"
        id="tytan"
        borderColor="#1a3f1788"
        imgList={listTytan}
        catalogLink="https://drive.google.com/file/d/14JkFGyi_fXnKyiZf6NHWV6NGdieN8cdR/view?usp=sharing"
        brandCode="516906"
      />
      <BrandSection
        breakpoint={95}
        startColor={colors.backgroundColors.green[2]}
        endColor={colors.backgroundColors.purple[0]}
        textList={brands.castor}
        buttonColor={colors.buttonColors.purple}
        Logo={logoCastor}
        LogoSize={logoSize.castor}
        cardBackground="#EBF6E9"
        dotListClass="dot-list-green"
        id="castor"
        borderColor="#1a3f1788"
        imgList={listCastor}
        catalogLink="https://drive.google.com/file/d/1j6mGi6xlCTlmO8RRPm6V8urbCQPR5cTI/view?usp=sharing"
        brandCode="576695"
      />
    </BrandsTemplate>
  )
}

