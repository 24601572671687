import img1 from "../../assets/promotionCarousel/imagem1.jpg"
import paredesTelhados from "../../assets/promotionCarousel/paredesTelhados.mp4"
import imgTelhadosLajes from "../../assets/promotionCarousel/telhadosLages.png"
import tytanMisc from "../../assets/promotionCarousel/tytanMisc.jpg"
import img5 from "../../assets/promotionCarousel/imagem5.jpg"
import img6 from "../../assets/promotionCarousel/imagem6.jpg"
import videoTelhadosLages from "../../assets/videos/telhadosLajes.mp4"


export const ImgList = [imgTelhadosLajes, videoTelhadosLages, img1, paredesTelhados, tytanMisc];
