import styled from "@emotion/styled";
import { breakpoints } from "../../global/breakpoints";

export const Image = styled.img`
  height: 250px;
  width: 100%;
  object-fit: fill;
  object-position: left;
  @media(min-width: ${breakpoints.tablet}) {
    height: 400px;

  }
  @media(min-width: ${breakpoints.desktop}) {
    height: 450px;
    object-fit: cover;
  }
`