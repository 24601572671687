import styled from "@emotion/styled";
import { Button } from "../button"
import { ButtonText } from "../../global/typography"
import OpenIcon from "../../assets/openNew.svg"
import { ShoppingCartTwoTone } from "@mui/icons-material";
import { colors } from "../../global";

export const OpenStoreButton = (props: { backgroundColor: string, code: string }) => {

  const Icon = styled(ShoppingCartTwoTone)`
  width: 20px;
  height: 20px;
  fill: ${colors.lightText};
  `
  const Container = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  `

  return (
    <Button backgroundColor={props.backgroundColor} width="180px" onClick={() => window.open(`https://shop.lamadorepresentacoes.com.br/?representada=${props.code}`)}>
      <Container>
        <Icon />
        <ButtonText>Ver mais na loja</ButtonText>
      </Container>
    </Button>
  )
} 