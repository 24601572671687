import esmalteDuplaAcao from "./sinteplast-esmalte-dupla-acao.jpg"
import fibratto from "./sinteplast-fibratto.jpg"
import massaAcrilica from "./sinteplast-massa-acrilica-multisuperficies.jpg"
import paredesMuros from "./sinteplast-paredes-e-muros.jpg"
import protegeDecora from "./sinteplast-protege-e-decora.jpg"
import telhadosLages from "./sinteplast-telhados-e-lajes.jpg"
import { Product } from "../../global/types";


export const list: Product[] = [
  {
    displayName: "Telhados e lajes",
    src: telhadosLages
  },
  {
    displayName: "Paredes e muros",
    src: paredesMuros
  },
  {
    displayName: "Fibratto",
    src: fibratto
  },
  {
    displayName: "Protege e decora",
    src: protegeDecora
  },
  {
    displayName: "Massa acrílica multi superfícies",
    src: massaAcrilica
  },
  {
    displayName: "Esmalte dupla ação",
    src: esmalteDuplaAcao
  }
]
export {
  esmalteDuplaAcao,
  fibratto,
  massaAcrilica,
  paredesMuros,
  protegeDecora,
  telhadosLages
}

