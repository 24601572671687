import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { ImgList } from "./CarouselItems";
import { Image } from "./Image";
import { Container } from "./Container";
import styled from '@emotion/styled';
import { breakpoints } from '../../global/breakpoints';

const Video = styled.video`
  max-height: 250px;
  @media(min-width: ${breakpoints.tablet}){
    max-height: 400px;
  }
  @media(min-width: ${breakpoints.desktop}){
    max-height: 450px;
  }
`

const CustomPaper = styled(Paper)`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  @media(min-width: ${breakpoints.tablet}){
    height: 400px;
  }
  @media(min-width: ${breakpoints.desktop}){
    height: 450px;
  }
`

function Item(props: { key: number, item: any }) {
  if (!props.item.match(".mp4")) {
    return (
      <CustomPaper>
        <Image src={props.item}></Image>
      </CustomPaper>
    )
  } else {
    return (
      <CustomPaper>
        <Video autoPlay loop controls muted><source src={props.item} type="video/mp4" /></Video>
      </CustomPaper>)
  }
}

export const PromotionCarousel = () => {
  return (
    <Container>
      <Carousel animation="slide" autoPlay={false} navButtonsAlwaysVisible>
        {
          ImgList.map((item, i) => <Item key={i} item={item} />)
        }

      </Carousel>
    </Container>
  )
}

